/* eslint-disable react/prop-types */
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Flex,
  Text,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';

import Loader from '../components/Loader';

const MessagesContainer = ({
  message,
  handleImageClick,
  bg,
  color = 'gray.900',
  colorDate = 'gray.500',
  messageId,
}) => {
  let dateTime = message?.time;
  let [date, time] = dateTime?.split(' ') ?? '';
  let [hours, minutes] = time?.split(':') ?? '';
  // Processes the message caption to extract URLs and separate text.
  let newDateTime = `${date} ${hours}:${minutes}`;
  // Extracts the caption property from the message object as a string.
  const urlCaption = `${message?.caption}`;
  //Defines a regular expression to match URLs starting with "https://".
  const regex = /https:\/\/\S+/g;
  //Uses the regex to find all URLs within the caption text.
  const links = urlCaption.match(regex);
  // Removes all URLs from the caption, leaving only the text.
  const textWithoutLinks = urlCaption.replace(regex, '');
  //Provides a function to copy text to the clipboard.
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
  };
  const userDetailsData = useSelector((state) => state.userDetails);
  const { userDetails } = userDetailsData;
  return (
    <Flex
      display={'flex'}
      direction={message?.pushname === 'user' ? 'row-reverse' : 'row'}
      justifyContent={message?.is_from_assistant || message?.from_me ? 'flex-end' : 'flex-start'}
      align="center"
      mt={2}
      width="100%"
      messageId={messageId}
    >
      <Box
        p={2}
        pr={1}
        pt={1}
        pb={2}
        maxW="sm"
        borderWidth="1px"
        borderRadius="15px"
        borderBottomEndRadius={(message?.is_from_assistant || message?.from_me) && 0}
        borderBottomStartRadius={!message?.is_from_assistant && !message?.from_me && 0}
        border
        boxShadow="md"
        bg={bg}
        fontSize="sm"
        maxWidth={'90%'}
        minWidth={'50%'}
        display={'flex'}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
        gap={1}
      >
        <Box m={0} p={0} width={'75%'}>
          <Box m={0}>
            {message?.pushname === 'user' ? (
              <Text fontWeight="bold" m={0}></Text>
            ) : message?.is_from_assistant ? (
              <Text fontWeight="bold" color={color} m={0}>
                Asistente:
              </Text>
            ) : (
              <Text fontWeight="bold" color={color} m={0} fontSize={12}>
                {message?.from_me ? 'Yo' : message?.pushname}:
              </Text>
            )}
            {userDetails?.is_staff && (
              <Text
                m={0}
                fontSize={'9px'}
                fontWeight={'normal'}
                color="gray.500"
                w="100%"
                cursor="pointer"
                onClick={() => handleCopy(messageId)}
              >
                {messageId}
              </Text>
            )}
          </Box>
          {message?.quoted_message_data && (
            <Box p={2} my={2} bg={'gray.50'} borderRadius={'md'} color={'gray.900'} w="100%">
              <Text m={0} fontSize={'xs'} opacity={1} fontWeight={'bold'}>
                {message?.quoted_message_data?.pushname}
              </Text>

              <Text m={0} fontSize={'sm'} fontWeight={'normal'} color="gray.900" w="100%">
                {message?.quoted_message_data?.body}
              </Text>
              {(message?.quoted_message_data?.attached_files?.[0] || message?.quoted_message_data?.image) && (
                <Image
                  src={message?.quoted_message_data?.attached_files?.[0] || message?.quoted_message_data?.image}
                  alt="Image"
                  maxH="64px"
                  mt={2}
                  cursor="pointer"
                  loading="lazy"
                  onClick={() =>
                    handleImageClick(message?.quoted_message_data?.attached_files?.[0] || message?.quoted_message_data?.image)
                  }
                />
              )}
            </Box>
          )}
          {message?.body &&
            message?.body.split('\n').map((text, i) => (
              <Text key={i} fontWeight="normal" color={color} m={0} p={0}>
                {text}
              </Text>
            ))}
          {message?.caption && (
            <Text fontWeight="normal" color={color} m={0} p={0}>
              {textWithoutLinks}
              <Link to={links[0]} target="_blank" rel="noopener noreferrer">
                <Text cursor={'pointer'} textDecoration={'underline'} color="blue.500">
                  Ir al enlace
                </Text>
              </Link>
            </Text>
          )}
          {/* Render attached files */}
          {message?.attached_files && message.attached_files.length > 0 && (
            <Box mt={2}>
              {message.attached_files.map((fileUrl, index) => (
                <Image
                  key={index}
                  src={fileUrl}
                  alt={`Attached file ${index + 1}`}
                  maxH="64px"
                  mt={2}
                  cursor="pointer"
                  onClick={() => handleImageClick(fileUrl)}
                />
              ))}
            </Box>
          )}
        </Box>
        {message?.time && (
          <Text
            fontSize="9px"
            p={0}
            m={0}
            color={colorDate}
            textAlign="right"
            fontWeight="bold"
            width={'47%'}
            whiteSpace={{ xxl: 'nowrap' }}
          >
            {newDateTime}
          </Text>
        )}
      </Box>
    </Flex>
  );
};

const FullChatComponent = ({
  handleImageClick,
  handleCloseModal,
  loading,
  messages,
  priorMessages,
  laterMessages,
  messagesRef,
  isEndOfConversation,
  selectedImage,
  laterMessagesRef,
}) => {
  const messageTrip = [messages];

  return (
    <Box>
      {loading ? (
        <Loader />
      ) : (
        <>
          {priorMessages &&
            priorMessages.map((message, index) => (
              <MessagesContainer
                message={message}
                key={index}
                messageId={message?._id}
                handleImageClick={handleImageClick}
                bg={message?.pushname === 'user' || message?.is_from_assistant ? 'blue.200' : 'blue.100'}
              />
            ))}

          {messageTrip?.length > 0 &&
            messageTrip.map((message, index) => (
              <div ref={messagesRef} cy="main-message" key={index}>
                <MessagesContainer
                  message={message}
                  handleImageClick={handleImageClick}
                  bg={message?.pushname === 'user' ? 'blue.100' : 'primary.500'}
                  color="white"
                  colorDate="white"
                  messageId={message?._id}
                />
              </div>
            ))}

          {laterMessages &&
            laterMessages.map((message, index) => (
              <div ref={laterMessagesRef} key={index}>
                <MessagesContainer
                  message={message}
                  handleImageClick={handleImageClick}
                  messageId={message?._id}
                  bg={
                    message?.pushname === 'user' || message?.is_from_assistant || message?.from_me
                      ? 'blue.200'
                      : 'blue.100'
                  }
                />
              </div>
            ))}
          {isEndOfConversation ? (
            <Text fontSize="xs" mt={2} color="gray.800" textAlign="center">
              Fin de la conversación
            </Text>
          ) : (
            <Text fontSize="xs" mt={2} color="gray.800" textAlign="center">
              Conversación continua..
            </Text>
          )}

          {/* Modal for expanding images */}
          {selectedImage && (
            <Modal isOpen={true} onClose={handleCloseModal} size="full">
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton color="white" backgroundColor="red.500" width={40} fontWeight="bold" fontSize={16}>
                  <CloseIcon mr={2} />
                  Cerrar imagen{' '}
                </ModalCloseButton>
                <ModalBody>
                  <Image src={selectedImage} alt="Expanded Image" maxW="100%" maxH="100%" />
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </Box>
  );
};

export default FullChatComponent;
