import React from 'react';

import { Box, Flex, Grid, Text, Image } from '@chakra-ui/react';

import ActionsAvailabilityButton from '../utils/buttons/ActionsAvailabilityButton';
import GridTripSkeleton from '../utils/skeletons.jsx/GridTripSkeleton';
//todo: this is duplicateeeee many time!

//this file is responsive view for availabilities screen
const CardText = ({ name, description, onClick }) => {
  return (
    <Box display={'flex'} fontSize="xs" m={0} p={1} pl={0} onClick={onClick} cursor={'pointer'} cy={`card-${name}`}>
      <Text m={0} p={0} pr={1} fontWeight="bold" cy="item-title">
        {name}:
      </Text>
      <Text m={0} p={0} cy={`card-${name}-description`}>
        {description}
      </Text>
    </Box>
  );
};
const CardItem = ({
  client,
  origin,
  destination,
  readStatus,
  message,
  openDetailModal,
  handleReadStatusToggle,
  unit,
  item,
  created_at,
  disabledButtons,
  openSureModal,
  openDetailMessage,
  openStatusesModal,
  openChatModal,
}) => {
  const id = item.id;
  return (
    <Box
      bg={'white'}
      border="1px solid"
      shadow={'md'}
      borderColor={'gray.200'}
      rounded={5}
      px={4}
      pt={2}
      pb={2}
      color="gray.900"
      fontWeight={'normal'}
      width={'100%'}
      m={0}
      mt={1}
    >
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Box
          bg="blue.50"
          minW="60px"
          rounded="2xl"
          textAlign={'center'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          p={1}
          px={2}
          cursor={'pointer'}
          onClick={() => handleReadStatusToggle(id)}
        >
          <Box w="10px" h="10px" bg={readStatus[id] ? 'gray.400' : 'green.300'} rounded={'3xl'} mr="4px"></Box>
          <Text fontSize={10} m={0} fontWeight={'medium'}>
            {readStatus[id] ? 'Leído' : 'No leído'}
          </Text>
        </Box>
      </Box>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        m={0}
        p={0}
      >
        <CardText name="Cliente" description={client} onClick={() => openDetailModal(id)} />
        <CardText name="Origen" description={origin} onClick={() => openDetailModal(id)} />
      </Grid>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        m={0}
        p={0}
      >
        <CardText name="Destino" description={destination || '-'} onClick={() => openDetailModal(id)} />
        <CardText name="Fecha" description={created_at || '-'} onClick={() => openDetailModal(id)} />
      </Grid>

      <Box>
        <Grid
          templateColumns={{
            base: '1fr',
            md: 'repeat(2, 1fr)',
          }}
          m={0}
          p={0}
        >
          <CardText name="Unidad" description={unit || ' -'} onClick={() => openDetailModal(id)} />
          <CardText name="Fecha Disponible" description={item?.start_date || '-'} onClick={() => openDetailModal(id)} />
        </Grid>

        <CardText name="Mensaje" description={message[0]?.body} onClick={() => openChatModal(id)} />
        {message[0]?.attached_files && message[0]?.attached_files.length > 0 && !message[0]?.body && (
          <Box onClick={() => {}}>
            <Image src={message[0]?.attached_files[0]} alt="Image" maxH="160px" maxW={'160px'} cursor="pointer" />
          </Box>
        )}
      </Box>

      <Flex justifyContent={'space-between'} alignItems={'center'} gap={2}>
        <Box></Box>
        <ActionsAvailabilityButton
          id={id}
          readStatus={readStatus}
          disabledButtons={disabledButtons}
          handleReadStatusToggle={handleReadStatusToggle}
          openSureModal={openSureModal}
          openDetailMessage={openDetailMessage}
          openStatusesModal={openStatusesModal}
        />
      </Flex>
    </Box>
  );
};
const AvailabilitiesRequest = ({
  data,
  loading,
  readStatus,
  openDetailModal,
  handleReadStatusToggle,
  openSureModal,
  openDetailMessage,
  openStatusesModal,
  disabledButtons,
  openChatModal,
}) => {
  return (
    <Box
      overflowY="auto"
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      height={{ base: '90%', xl: '0px' }}
      width={'100%'}
    >
      {loading ? (
        <GridTripSkeleton />
      ) : data.length === 0 ? (
        <Box
          ml="10px"
          w={'100%'}
          minW={'420px'}
          p={0}
          m={0}
          pt={4}
          height={240}
          justifyContent="center"
          alignItems="center"
          bg="white"
          shadow={'md'}
          rounded={5}
          mb={3}
          display={{ base: 'flex', xl: 'none' }}
        >
          <Text fontWeight="normal">No hay disponibilidades</Text>
        </Box>
      ) : (
        <Grid
          templateColumns={{ base: '1fr' }}
          width={'100%'}
          display={{ base: 'block', xl: 'none' }}
          m={0}
          p={0}
          mb={4}
        >
          {data?.map((item, i) => (
            <CardItem
              key={i}
              client={item.carrier_name} //check
              origin={item.origin} // check
              destination={item.destination} //check
              created_at={item.created_at} //date
              message={item.main_msg} //check
              unit={item.unit_type} // check
              item={item}
              readStatus={readStatus}
              openDetailModal={openDetailModal}
              handleReadStatusToggle={handleReadStatusToggle}
              openSureModal={openSureModal}
              openDetailMessage={openDetailMessage}
              openStatusesModal={openStatusesModal}
              disabledButtons={disabledButtons}
              openChatModal={openChatModal}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default AvailabilitiesRequest;
