import React from 'react';

import { Box, Flex, Grid, Button, Text, useDisclosure, Image, Checkbox } from '@chakra-ui/react';

import ContainerTripRequestButton from '../utils/buttons/ActionsTripRequestButtonOutlines';
import GridTripSkeleton from '../utils/skeletons.jsx/GridTripSkeleton';
import StatusesToolTip from '../utils/tooltips/StatusesToolTip';
import PendingStatusProcess from './utils/PendingStatusProcess';

const CardText = ({ name, description, onClick, id }) => {
  return (
    <Box
      display={'flex'}
      fontSize="xs"
      m={0}
      p={1}
      pl={0}
      onClick={onClick}
      cursor={'pointer'}
      cy={`card-${name}`}
      id={id}
    >
      <Text m={0} p={0} pr={1} fontWeight="bold" cy="item-title">
        {name}:
      </Text>
      <Text m={0} p={0} cy={`card-${name}-description`}>
        {description}
      </Text>
    </Box>
  );
};
const CardItem = ({
  client,
  origin,
  destination,
  requested_at,
  loading_date,
  message,
  asigned,
  unit,
  item,
  openChatModal,
  hasSupplierCoordination,
  handleOpenConversationsModal,
  openForwardMessageModal,
  setShowModal,
  handleReadStatusToggle,
  readStatus,
  disabledButtons,
  openTripRequestModal,
  checkedItems,
  handleCheckboxChange,
  handleRejectedModal,
  index,
  isApprovedByMC,
  handleAssignModal,
  openAssignSupplierTrafficModal,
  assignedTransporter,
  hasClientCoordination,
  isFile,
  refreshTripRequests,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const id = item.id;

  return (
    <Box
      bg={'white'}
      border="1px solid"
      shadow={'md'}
      borderColor={'gray.200'}
      rounded={5}
      px={4}
      pt={2}
      pb={2}
      color="gray.900"
      fontWeight={'normal'}
      width={'100%'}
      m={0}
      mt={1}
    >
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} mb={1}>
        <Flex gap={1}>
          <Checkbox
            borderColor={'gray.600'}
            isChecked={checkedItems?.some((item) => item.id === id)}
            onChange={(event) =>
              handleCheckboxChange({ index: index, id: id, supplierId: item?.preassigned_transporter }, event)
            }
          />
          <StatusesToolTip row={item} />
        </Flex>
        <Flex gap={1}>
          {!!item?.file && !hasClientCoordination && (
            <button
              onClick={() =>
                handleAssignModal({
                  id: item.id,
                  forwarded_supplier_chats: item.forwarded_supplier_chats,
                  is_rejected: item.is_rejected,
                  origin: item?.origin,
                  destination: item.destination,
                  client_name: item.client_name,
                })
              }
            >
              <PendingStatusProcess
                row={item}
                isMessageTrip={!item?.file}
                contactedSupplier={item?.suppliers_have_been_contacted}
                isAssignedATrasnporter={assignedTransporter}
              />
            </button>
          )}
          <Box
            bg="blue.50"
            minW="60px"
            rounded="2xl"
            textAlign={'center'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            p={1}
            px={2}
            cursor={'pointer'}
            onClick={() => handleReadStatusToggle(id)}
          >
            <Box w="10px" h="10px" bg={readStatus[id] ? 'gray.400' : 'green.300'} rounded={'3xl'} mr="4px"></Box>
            <Text fontSize={10} m={0} fontWeight={'medium'}>
              {readStatus[id] ? 'Leído' : 'No leído'}
            </Text>
          </Box>
        </Flex>
      </Box>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        m={0}
        p={0}
      >
        <CardText name="Cliente" description={client} onClick={() => openTripRequestModal(id, isFile)} id={id} />
        <CardText name="Origen" description={origin} onClick={() => openTripRequestModal(id, isFile)} />
      </Grid>
      <Grid
        templateColumns={{
          base: '1fr',
          md: 'repeat(2, 1fr)',
        }}
        m={0}
        p={0}
      >
        <CardText name="Destino" description={destination} onClick={() => openTripRequestModal(id, isFile)} />
        <CardText name="Fecha" description={requested_at} onClick={() => openTripRequestModal(id, isFile)} />
      </Grid>
      {isOpen && (
        <Box>
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
            }}
            m={0}
            p={0}
          >
            <CardText
              name="Fecha de carga"
              description={`${loading_date || ''} / ${item.loading_time || ''}`}
              onClick={() => openTripRequestModal(id, isFile)}
            />
            <CardText
              name="Fecha de descarga"
              description={`${item?.unloading_date || ''} / ${item.unloading_time || ''}`}
              onClick={() => openTripRequestModal(id, isFile)}
            />
          </Grid>
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
            }}
            m={0}
            p={0}
          >
            <CardText name="Asignado" description={asigned || ' -'} onClick={() => openTripRequestModal(id, isFile)} />
            <CardText name="Unidad" description={unit || ' -'} onClick={() => openTripRequestModal(id, isFile)} />
          </Grid>
          <Grid
            templateColumns={{
              base: '1fr',
              md: 'repeat(2, 1fr)',
            }}
            m={0}
            p={0}
          >
            <CardText
              name="Número de viaje"
              description={item?.shipment_number || ' -'}
              onClick={() => openTripRequestModal(id, isFile)}
            />
            <CardText
              name="Número de confirmación"
              description={item?.folio_number || ' -'}
              onClick={() => openTripRequestModal(id, isFile)}
            />
          </Grid>

          <CardText name="Mensaje" description={message[0]?.body} onClick={() => openChatModal(id)} />
          {!isFile && message[0]?.attached_files && message[0]?.attached_files.length > 0 && !message[0]?.body && (
            <Box onClick={() => openChatModal(id)}>
              <Image src={message[0]?.attached_files[0]} alt="Image" maxH="160px" maxW={'160px'} cursor="pointer" />
            </Box>
          )}
        </Box>
      )}
      <Flex justifyContent={'space-between'} alignItems={'center'} gap={2}>
        <Button
          onClick={onToggle}
          variant="ghost"
          color="gray.500"
          p={0}
          m={0}
          textAlign={'center'}
          size="sm"
          textDecoration={'underline'}
          _hover={{
            backgroundColor: 'transparent',
            color: 'blue.500',
          }}
          cy="card-toggle-button"
        >
          {!isOpen ? 'Ver más' : 'Ver menos'}
        </Button>
        <ContainerTripRequestButton
          openChatModal={openChatModal}
          hasSupplierCoordination={hasSupplierCoordination}
          handleOpenConversationsModal={handleOpenConversationsModal}
          openForwardMessageModal={openForwardMessageModal}
          setShowModal={setShowModal}
          handleReadStatusToggle={handleReadStatusToggle}
          readStatus={readStatus}
          disabledButtons={disabledButtons}
          id={item.id}
          forwarded_supplier_chats={item.forwarded_supplier_chats}
          assigned_transporter={item.assigned_transporter}
          handleRejectedModal={() => handleRejectedModal(id, item?.is_rejected)}
          isApprovedByMC={isApprovedByMC}
          item={item}
          handleAssignModal={handleAssignModal}
          openAssignSupplierTrafficModal={openAssignSupplierTrafficModal}
          isABid={!!isFile}
          refreshTripRequests={refreshTripRequests}
        />
      </Flex>
    </Box>
  );
};
const GridTripsRequest = ({
  data,
  openChatModal,
  hasSupplierCoordination,
  handleOpenConversationsModal,
  openForwardMessageModal,
  setShowModal,
  handleReadStatusToggle,
  readStatus,
  disabledButtons,
  openTripRequestModal,
  loading,
  checkedItems,
  handleCheckboxChange,
  handleRejectedModal,
  handleAssignModal,
  openAssignSupplierTrafficModal,
  hasClientCoordination,
  refreshTripRequests,
}) => {
  return (
    <Box
      overflowY="auto"
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
      height={{ base: '90%', xl: '0px' }}
      width={'100%'}
    >
      {loading ? (
        <GridTripSkeleton />
      ) : data.length === 0 ? (
        <Box
          ml="10px"
          w={'100%'}
          p={0}
          m={0}
          pt={4}
          height={240}
          justifyContent="center"
          alignItems="center"
          bg="white"
          shadow={'md'}
          rounded={5}
          mb={3}
          display={{ base: 'flex', xl: 'none' }}
        >
          <Text fontWeight="normal">No hay solicitudes de viaje</Text>
        </Box>
      ) : (
        <Grid
          templateColumns={{ base: '1fr' }}
          width={'100%'}
          display={{ base: 'block', xl: 'none' }}
          m={0}
          p={0}
          mb={4}
        >
          {data?.map((item, i) => (
            <CardItem
              key={i}
              client={item.client_name} //check
              origin={item.origin} // check
              destination={item.destination} //check
              requested_at={item.requested_at} //date
              loading_date={item.loading_date} //charge date
              message={item.main_msg} //check
              asigned={item.assigned_transporter}
              unit={item.unit_type} // check
              isFile={item?.file} // check
              item={item}
              openChatModal={openChatModal}
              hasSupplierCoordination={hasSupplierCoordination}
              handleOpenConversationsModal={handleOpenConversationsModal}
              openForwardMessageModal={openForwardMessageModal}
              setShowModal={setShowModal}
              handleReadStatusToggle={handleReadStatusToggle}
              readStatus={readStatus}
              disabledButtons={disabledButtons}
              openTripRequestModal={openTripRequestModal}
              checkedItems={checkedItems}
              handleCheckboxChange={handleCheckboxChange}
              index={i}
              handleRejectedModal={handleRejectedModal}
              isApprovedByMC={item?.is_approved}
              handleAssignModal={handleAssignModal}
              openAssignSupplierTrafficModal={openAssignSupplierTrafficModal}
              assignedTransporter={item.assigned_transporter}
              hasClientCoordination={hasClientCoordination}
              refreshTripRequests={refreshTripRequests}
            />
          ))}
        </Grid>
      )}
    </Box>
  );
};

export default GridTripsRequest;
