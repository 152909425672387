import React, { useEffect, useState } from 'react';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import VerticalCollapser from '../../components/pipelineDetails/View';
import PipelineEvent from '../../components/pipelineDetails/Event';
import styled from '@emotion/styled';
import DataTable from '../../components/pipelineDetails/Table';
import httpClient from '../../actions/utils/axiosInstance';
import { ArrowBackIcon } from '@chakra-ui/icons';


const Icon = styled(IconButton)`
  :hover {
    after {
      content: "Back to chats";
      width: 100px;
      height: 100px;
      border: 1px solid black;
      background-color: white;
      position: absolute;
      

    }
  }
`

const Container = styled.div`
  padding: 1em;
`;

const MessageDetails = ({ messageId, onClose, messageDetails }) => {

  const handleBack = () => {
    onClose();
  };

  const fetchPipeline = async (pipelineId) => {
    try {
      const { data } = await httpClient.get(`/api/v1/pipeline/${pipelineId}`)
      return data;
    } catch (error) {
      console.error('Error fetching pipeline data:', error);
      return null;
    }
  };

  const [pipelineData, setPipelineData] = useState([]);
  const [isImageFullWidth, setIsImageFullWidth] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPipelineData = async () => {
      if (messageId) {
        const data = await fetchPipeline(messageId);
        setPipelineData(data);
        setLoading(false);
      }
    };

    loadPipelineData();
  }, [messageId]);

  const eventTabs = pipelineData?.events?.map((event, index) => {
    // TODO [START] It should not be handled here instead the data should be handled in the backend
    const previousEventsTables = pipelineData?.events?.slice(0, index).filter((e) => e.prompt?.tables?.length > 0);
    const onlyOneEventsHaveTables = previousEventsTables.length === 1;

    if (onlyOneEventsHaveTables) {

      const firstEventTables = previousEventsTables[0].prompt.tables;

      const previousEvent = pipelineData.events[index - 1];

      const previousHasTable = previousEvent.prompt?.tables?.length > 0;
      const currentEvenHasNotData = !event?.prompt?.data || event?.prompt?.data?.length == 0;

      if (currentEvenHasNotData && previousHasTable) {
        if (!event.prompt) {
          event.prompt = {}
        }
        event.prompt.inputData = [...firstEventTables]
        const columnFormat = event?.output?.response?.columns_format

        event.prompt.output_headers = event?.output?.response?.columns_format

        console.log({ firstEventTables })

      }
    }
    // TODO [END] It should not be handled here instead the data should be handled in the backend

    return {
      title: `${index}# ${event.service.name} - id: ${event.service.id}`,
      content: <PipelineEvent event={event} />,
    };
  })


  const createdEntities = pipelineData?.created_entities
  const tripRequests = createdEntities?.trip_requests

  const flattenObject = (obj, parentKey = '', result = {}) => {
    Object.keys(obj).forEach((key) => {
      const newKey = parentKey ? `${parentKey}_${key}` : key;

      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        !Array.isArray(obj[key])
      ) {
        flattenObject(obj[key], newKey, result);
      } else {
        result[newKey] = obj[key];
      }
    });
    return result;
  }

  const flattedTripTequests = tripRequests?.map((tripRequest) => {
    const flattened = flattenObject(tripRequest);
    return flattened;
  })


  const createdEntitiesTab = flattedTripTequests && flattedTripTequests.length > 0 ? {
    title: 'Created Entities',
    content: (
      <div style={{ display: "grid", justifyContent: "space-between", alignItems: "center", gap: "1rem", padding: "1rem" }}>
        <h4>Trip Requests</h4>
        <DataTable data={flattedTripTequests} />
      </div>
    ),
  } : null;




  return (
    <Box position={"relative"} width="100%" height="100%">

      <Icon
        position={"absolute"}
        top={"9px"}
        right={"-18px"}
        bg={"blue.800"}
        color={"white"}
        colorScheme='blue'
        size='sm'
        borderRadius={"50%"}
        onClick={() => {
          window.history.pushState({}, '', '/admin');
          handleBack();
        }}

        aria-label="Back to chats"

        zIndex={9999999999999}
        icon={<Tooltip placement='auto-start' label="Back to chats" aria-label='Back to chats'><ArrowBackIcon /></Tooltip>
        } />

      <Box width="100%" height="30%" bg="gray.200" position="relative">
        <Box display="flex" width="100%" height="100%">
          <Box
            width="70%"
            p={4}
            style={{ width: isImageFullWidth ? '0%' : '70%' }}
          >
            <textarea
              style={{ width: '100%', height: '100%', resize: 'none', padding: '1rem' }}
              placeholder="Type your message here..."
              value={messageDetails?.message_data.body}
              readOnly
            />
          </Box>
          <Box
            p={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{ width: isImageFullWidth ? '100%' : '30%', cursor: 'pointer' }}
          >
            {messageDetails && messageDetails.message_data.attached_files && messageDetails.message_data.attached_files.length > 0 && (
              <img
                src={`${messageDetails.message_data.attached_files[0]}`}
                alt="Message Attachment"
                style={{ width: isImageFullWidth ? '100%' : 'auto', cursor: 'pointer' }}
                onClick={() => setIsImageFullWidth(!isImageFullWidth)}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box width="100%" height="70%" bg="gray.200" overflow="scroll">
        {pipelineData?.events && (
          <Container>
            <VerticalCollapser tabs={[...eventTabs, createdEntitiesTab].filter(_ => _)} />
          </Container>
        )}
      </Box>
    </Box>
  );
};

export default MessageDetails;

